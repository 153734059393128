import React from "react";

import Hero from "../../components/Hero/Hero";
import WorkExperience from "../../components/WorkExperience/WorkExperience";
import Education from "../../components/Education/Education";
import Skills from "../../components/Skills/Skills";
import Awards from "../../components/Awards/Awards";
import Projects from "../../components/Projects/Projects";
import Certificates from "../../components/Certificates/Certificates";


export default function Home() {
    return (
        <>
            <Hero />
            <WorkExperience />
            <Education />
            <Skills />
            <Certificates />
            <Awards />
            <Projects />
        </>
    )
}