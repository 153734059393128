import React from "react";
import { useTranslation } from 'react-i18next';
import WorkExperienceItemDesc from "./WorkExperienceItemDesc";


export default function WorkExperienceItem({ data }) {
    const { t } = useTranslation();

    return (
        <div className="row timeline-item">
            <div className="item-date col-12 col-lg-auto">
                <div className="date-box text-center bg-dark d-inline-block">
                    <h4 className="fs-5 p-2 m-0 text-white">
                        {data.start ? <> {data.start} <span className="d-inline d-lg-block fs-6"> - </span></> : null}
                        {data.end ? data.end : t('common.today')}
                    </h4>
                </div>
            </div>

            <div className="item-details col">
                <h3 className="item-title fs-5 d-block pt-2 fw-bold">{data.name} | {data.company}</h3>
                <div className="item-desc">
                    {data.desc
                        ? <ul>
                            {data.desc.map((item, index) => {
                                return <WorkExperienceItemDesc key={`desc-${index}`} data={item} />;
                            })}
                        </ul>
                        : ""
                    }

                    {data.key_success
                        ? <p><b>{t('common.key_success')}:</b> {data.key_success}</p>
                        : ""
                    }
                </div>
            </div>
        </div>
    )

}