export const en = {
    "common": {
        "about_myself": "About myself",
        "work": "Work experience",
        "education": "Education",
        "skills": "Skills",
        "certificates": "Certificates",
        "awards": "Awards",
        "projects": "Projects",
        "key_success": "Key success",
        "today": "today",
        "imprint": "Imprint",
        "privacy": "Privacy Policy"
    },
    "about_myself": {
        "greeting": "Hello Everybody, I am",
        "job": "Developer and Project Manager",
        "about_myself_desc_1": "I am passionate about the tech sector and coding. I enjoy learning new skills, creating new things, or improving existing ones. With a bachelor’s degree in media informatics and business administration, I possess a solid understanding of technology and company processes.",
        "about_myself_desc_2": "Feel free to explore this website to learn more about my work experience, education, skills, and projects. I am excited to share my passion for technology with you and I am open to new challenges and opportunities.",
        "about_myself_desc_3": "Thank you for visiting!",
        "social_profiles": "Check my social profiles"
    },
    "work_experience": [
        {
            "name": "Training as an Junior Java Full Stack Developer",
            "company": "YouGrow",
            "start": "09.2024",
            "end": "12.2024",
            "desc": [
                "Java, Object-oriented programming",
                "Git, Maven, Intellij",
                "Unit-Testing, Test-Driven Development, Code Quality",
                "Spring Boot, JPA, Spring Web, Spring Data",
                "JavaScript, HTML & CSS",
                "Angular programming"
            ],
        },
        {
            "name": "Continued Education through Online Courses",
            "company": "Private",
            "start": "01.2024",
            "end": "09.2024",
            "desc": [
                "Focus on professional development",
                "Front-End and Full-Stack courses on Codecademy",
                "Additional courses on TypeScript and AI",
                "Meta Front-End Developer course on Coursera"
            ],
        },
        {
            "name": "Working student, Career Site Development",
            "company": "SAP SE, Walldorf",
            "start": "2021",
            "end": "2023",
            "desc": [
                "Maintenance and development of the global SAP career site (jobs.sap.com)",
                "Landing page development and optimization",
                "Support in the development of a new design concept for the career site",
                "Development and implementation of the design concept",
                "Development and implementation of automated website testing processes"
            ],
            "key_success": "Developed and documented reusable layout elements (web components with Stencil.js), which are also used by SAP customers."
        },
        {
            "name": "Global IT Manager",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2019",
            "end": "2021",
            "desc": [
                "Execution and management of central IT web development projects",
                "Definition and coordination of technical requirements for development tasks",
                "Creation and management of technical documentations",
                "Ensuring the functionality and usability of developed applications",
                "Data management",
                "Development of dynamic APIs"
            ],
            "key_success": 'Successfully launched the “Voltimum+” loyalty program.'
        },
        {
            "name": "IT Project Manager",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2017",
            "end": "2018",
            "desc": [
                "Management of customer IT projects in the area of web development",
                "Requirements engineering and prototyping",
                "Coordination of tasks with developers",
                "Processing of customer tickets",
                "Advising clients on website optimization"
            ],
            "key_success": "Successfully migrated several large customer websites to new Drupal major versions."
        },
        {
            "name": "Content and Marketing Manager",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2014",
            "end": "2016",
            "desc": [
                "Content management and portal management for DACH",
                "Programming of campaign pages",
                "Programming of the Voltimum newsletter",
                "Support in the planning and implementation of IT services projects"
            ],
            "key_success": "Developed and implemented the Voltimum newsletter tool."
        },
        {
            "name": "Internship, Marketing Assistant",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2012",
            "end": "2013",
        },
        {
            "name": "Internship, Administrative Assistant",
            "company": "Bilfinger Berger, Mannheim",
            "end": "2009"
        },
        {
            "name": "Internship, Bicycle Mechanic",
            "company": "Bike und Sport, Eberbach",
            "end": "2005"
        }
    ],
    "education": [
        {
            "name": "Media Informatics (B.Sc.)",
            "organisation": "IU Internationale Hochschule",
            "end": "2023",
            "desc": [
                'Specialisation: "E-Commerce" and "Software Development with Python"',
                "Distance Learning",
            ]
        },
        {
            "name": "Business Administration (B.A.)",
            "organisation": "SRH Hochschule, Heidelberg",
            "end": "2013",
            "desc": [
                'Specialisation: "Marketing" und "Sports Management"',
                "On-campus study"
            ]
        },
        {
            "name": "General qualification for university entrance",
            "organisation": "SRH Stephen-Hawking-Schule, Neckargemünd",
            "end": "2009"
        }
    ],
    "skills": {
        "skill_block_desc_1": "This is a comprehensive overview of the skills and competencies I've developed throughout my career. From technical skills to soft skills, I've cultivated a diverse skill set that enables me to tackle challenges effectively and contribute meaningfully to projects and teams.",
        "skill_block_desc_2": "Click the categories below to explore my skills in more detail.",
        "skillList": [
            {
                "category_name": "Front-End",
                "category_skills": [
                    {
                        "name": "HTML",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "CSS",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "JavaScript ",
                        "rating": 75,
                        "text": ""
                    },
                    {
                        "name": "Stencil.js",
                        "rating": 70,
                        "text": ""
                    },
                    {
                        "name": "Bootstrap Framework",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "Angular",
                        "rating": 50,
                        "text": ""
                    },
                    {
                        "name": "React",
                        "rating": 50,
                        "text": ""
                    },
                ]
            },
            {
                "category_name": "Back-End",
                "category_skills": [
                    {
                        "name": "SQL",
                        "rating": 70,
                        "text": ""
                    },
                    {
                        "name": "MySQL",
                        "rating": 65,
                        "text": ""
                    },
                    {
                        "name": "PHP",
                        "rating": 15,
                        "text": ""
                    },
                    {
                        "name": "Java",
                        "rating": 60,
                        "text": ""
                    },
                    {
                        "name": "Spring Web",
                        "rating": 50,
                        "text": ""
                    },
                    {
                        "name": "Node.js",
                        "rating": 45,
                        "text": ""
                    },
                    {
                        "name": "Express.js",
                        "rating": 40,
                        "text": ""
                    },
                ]
            },
            {
                "category_name": "Testing",
                "category_skills": [
                    {
                        "name": "Cypress",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Katalon",
                        "rating": 75,
                        "text": ""
                    }
                ]
            },
            {
                "category_name": "CMS",
                "category_skills": [
                    {
                        "name": "Drupal",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "WordPress",
                        "rating": 60,
                        "text": ""
                    }
                ]
            },
            {
                "category_name": "Software/Tools",
                "category_skills": [
                    {
                        "name": "Axure RP",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "Git",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Jira",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Microsoft Office",
                        "rating": 90,
                        "text": ""
                    },
                    {
                        "name": "Visual Studio Code",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "Wrike",
                        "rating": 70,
                        "text": ""
                    },
                    {
                        "name": "Figma",
                        "rating": 40,
                        "text": ""
                    },
                    {
                        "name": "Docker",
                        "rating": 20,
                        "text": ""
                    },
                ]
            },
            {
                "category_name": "Operating Systems",
                "category_skills": [
                    {
                        "name": "Ubuntu",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Windows",
                        "rating": 95,
                        "text": ""
                    }
                ]
            },
            {
                "category_name": "Languages",
                "category_skills": [
                    {
                        "name": "German",
                        "rating": 100,
                        "text": ""
                    },
                    {
                        "name": "English",
                        "rating": 80,
                        "text": ""
                    }
                ]
            }
        ]
    },
    "awards": [
        {
            "name": 'Winner of the "Voltimum Millennial Challenge"',
            "organisation": "Voltimum GmbH",
            "end": "2016",
            "subtitle": "Award for special initiative and engagement for the company",
            "desc": "Outside of regular working hours, I developed a drag and drop newsletter tool that was used for many years in 13 countries. It significantly reduced the time needed to create newsletters."
        }
    ],
    "certificates": {
        "cta": "View certificate",
        "codecademy": "Find all my Codecademy certificates in my profile.",
        "codecademyCTA": "Show profile",
        "certList": [
            {
                "year": "2024",
                "name": "Junior Java Full Stack Developer",
                "organisation": "YouGrow Academy",
                "skills": "HTML, CSS, JavaScript, Java, Spring, Angular, Version Control",
                "link": "/files/Zertifikat_YouGrow.pdf"
            },
            {
                "year": "2024",
                "name": "Meta Front-End Developer",
                "organisation": "Meta on Coursera",
                "skills": "HTML, CSS, JavaScript, React, Front-End Development, UX/UI Design, Version Control",
                "link": "https://www.coursera.org/account/accomplishments/professional-cert/H5G2J63LW5SU"
            },
            {
                "year": "2024",
                "name": "Create REST APIs with Spring and Java Skill Path",
                "organisation": "Codecademy",
                "skills": "Java, Spring, REST APIs, CRUD, JPA",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/60f1edf0ac9368001c6025c4"
            },
            {
                "year": "2024",
                "name": "Learn Java Course",
                "organisation": "Codecademy",
                "skills": "Java, Object-Oriented Programming, Conditionals and Control Flow, etc.",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/d3f89367b558583e361640f778191345"
            },
            {
                "year": "2024",
                "name": "Learn TypeScript Course",
                "organisation": "Codecademy",
                "skills": "Types, Functions, Complex Types, Type Narrowing, Advanced Object Types",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/56fb1e71303e37b643bb1905f31c8a09"
            },
            {
                "year": "2024",
                "name": "Full-Stack Engineer Career Path",
                "organisation": "Codecademy",
                "skills": "HTML, CSS, JavaScript, React, Redux, Git, Datenbanken, Node.js, Express.js, SQL, Front-End Development, Back-End Development, Full-Stack Development",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/ffd0f42cce1a44e9a0108b365047a0a6"
            },
            {
                "year": "2024",
                "name": "Front-End Engineer Career Path",
                "organisation": "Codecademy",
                "skills": "Web Foundations, HTML, CSS, JavaScript, Front-End Development",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/2682884a0719474f96407efe432fdd87"
            }
        ]
    },
    "projects": {
        "desc": "This section highlights my latest coding projects, providing a glimpse into my development skills. However, I have undertaken a far wider range of programming tasks in my professional career. These include numerous campaign pages and newsletters, as well as the Voltimum newsletter tool and a tool for translating SAP's careers page. Unfortunately, I am unable to showcase these here. Additionally, I have managed many significant projects as an IT Project Manager, which are not detailed in this section.",
        "cta": "Show Project",
        "projectList": [
            {
                "name": "Quizmaster (Full-Stack)",
                "type": "Referenzprojekt",
                "img": "/img/quizmaster.jpg",
                "stack": "Angular, Spring, PostgreSQL, Bootstrap",
                "shortText": "The app Quizmaster was developed as part of the final project of the YouGrow Bootcamp. The development time was around 1 ½ weeks, during which we worked as a team of 4 people.",
                "link": "https://qm.steffen-napravnik.de"
            },
            {
                "name": "Little Lemon Restaurant (Full-Stack)",
                "type": "Showcase Project",
                "img": "/img/little-lemon-angular.jpg",
                "stack": "Angular, Spring (Java), PostgreSQL, Bootstrap, Mirage JS, Docker",
                "shortText": "As part of the YouGrow Bootcamp, I wanted to apply my newly acquired skills right away. Therefore, I migrated the Little Lemon project to Angular and added additional features. Furthermore, I built an API leveraging Spring.",
                "link": "https://llr.steffen-napravnik.de",
                "gitHub": "https://github.com/steffen-napravnik/Little-Lemon-Angular-Spring"
            },
            {
                "name": "Little Lemon Restaurant (Front-End)",
                "type": "Showcase Project",
                "stack": "React, Bootstrap",
                "img": "/img/little-lemon-react.jpg",
                "shortText": "This project was part of the Meta Front-End Developer course. The assignment involved creating a website for a fictional restaurant as a single-page application. This included designing the homepage and implementing a table reservation feature."
            },
            {
                "name": "My portfolio website",
                "type": "Real World Project",
                "stack": "React, Bootstrap 5",
                "img": "/img/portfolio.jpg",
                "shortText": "This project corresponds to the page you are currently visiting. As part of the Codecademy Front-End Engineer course, a portfolio website had to be created. Initially, I developed it as a static HTML page and later transformed it into a React single-page application.",
            },
            {
                "name": "SAP career site",
                "type": "Real World Project",
                "stack": "JavaScript, Bootstrap, StencilJS",
                "img": "/img/sap-careers.jpg",
                "shortText": "As a working student at SAP, I was responsible for maintaining and enhancing SAP's global career site. I developed reusable web components using StencilJS and Bootstrap, which improved maintainability and usability.",
                "link": "https://jobs.sap.com/"
            }
        ]
    },
    "privacy": {
        "data_collection": "Data Collection",
        "data_collection_text": "No personally identifiable information is collected, stored or processed by this website. This site does not use cookies.",
    }
}