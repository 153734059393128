import React, { useEffect } from "react";
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import "@popperjs/core";
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

// Attach Bootstrap components to the window object
window.bootstrap = bootstrap;


export default function Header() {
    const { t, i18n } = useTranslation();

    const handleLangChange = evt => {
        const lang = evt.target.getAttribute("lang");
        i18n.changeLanguage(lang);
    };

    const scrollNavBar = () => {
        var navbar = document.getElementById('navbar');
        var scroll = window.scrollY;
        if (scroll < 1) {
            navbar.classList.remove('bg-black');
        } else {
            navbar.classList.add('bg-black');
        }
    }

    
    const closeBurgerMenu = () => {
        const navbar = document.getElementById('navbarNavAltMarkup');
        if (navbar.classList.contains('show')) {
            const collapseElement = new window.bootstrap.Collapse(navbar);
            collapseElement.hide();
        }
    }

    useEffect(() => {
        scrollNavBar();
        window.addEventListener('scroll', scrollNavBar);

        // Close burger menu on link click
        const navLinks = document.querySelectorAll('#navbarNavAltMarkup .nav-link:not(.dropdown-toggle), #navbarNavAltMarkup .dropdown-item');
        navLinks.forEach(link => {
            link.addEventListener('click', closeBurgerMenu);
        });
    }, []);


    return (
        <nav id="navbar" className="navbar navbar-expand-lg">
            <div className="container">
                <HashLink className="navbar-brand" to="/#main">
                    <span className="fs-4 fw-bold text-white">Steffen Napravnik</span>
                </HashLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav ms-auto">
                        <HashLink className="nav-link" to="/#work">{t('common.work')}</HashLink>
                        <HashLink className="nav-link" to="/#edu">{t('common.education')}</HashLink>
                        <HashLink className="nav-link" to="/#skills">{t('common.skills')}</HashLink>
                        <HashLink className="nav-link" to="/#cert">{t('common.certificates')}</HashLink>
                        <HashLink className="nav-link" to="/#awards">{t('common.awards')}</HashLink>
                        <HashLink className="nav-link" to="/#projects">{t('common.projects')}</HashLink>
                        <div className="nav-item dropdown">
                            <HashLink className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa-solid fa-earth-europe"></i>
                            </HashLink>
                            <ul className="dropdown-menu">
                                <li><span className="dropdown-item" onClick={handleLangChange} lang="de">Deutsch</span></li>
                                <li><span className="dropdown-item" onClick={handleLangChange} lang="en">English</span></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </nav>
    );
}