import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";


export default function Layout() {

    let location = useLocation();

    useEffect(() => {
        if (location.hash) {
            document.getElementById(location.hash.replace("#", "")).scrollIntoView();
        }
    }, [location.hash])

    return (
        <div className="app">
            <header id="header" className="sticky-top">
                <Header />
            </header>
            <main id="main">
                <Outlet />
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}