import React from "react";
import {useTranslation} from 'react-i18next';

export default function PrivacyPolicy() {
    const {t} = useTranslation();
    return (
        <div id="hero-container" className="container-fluid">

            <div id="hero-inner" className="d-flex align-items-center p-3 p-sm-0">
                <div id="hero-box" className="container shadow-lg">
                    <div className="row p-0 py-3 p-sm-4 p-md-5">
                        <div className="col">
                            <section id="imprint" className="p-0">
                                <h2><i className="fa-solid fa-shield-halved"></i> {t('common.privacy')}</h2>
                                <h3 className="fs-4 fw-bold mt-2">{t('privacy.data_collection')}</h3>
                                <p>{t('privacy.data_collection_text')}</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <section style={{minHeight: "300px"}}></section>
        </div>
    )
}