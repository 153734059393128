import React from "react";

export default function SkillItem({ skill }) {
    return (
        <div className="skill-item col-12 col-md-6">
            <h4 className="fs-6">{skill.name}
                {skill.text ? <span tabIndex="0" data-bs-toggle="popover" data-bs-trigger="focus" data-bs-content={skill.text}><i className="fa-solid fa-circle-info ps-1"></i></span> : ""}
            </h4>

            <div className="progress" role="progressbar" aria-label={skill.name} aria-valuenow={skill.rating} aria-valuemin="0" aria-valuemax="100">
                <div className="progress-bar bg-dark" style={{ width: `${skill.rating}%` }}></div>
            </div>
        </div>
    )
}