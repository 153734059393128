import React from "react";
import { useTranslation } from 'react-i18next';
import WorkExperienceItem from "./WorkExperienceItem";


export default function WorkExperience() {
    const { t } = useTranslation();
    const positions = t("work_experience", { returnObjects: true });
    return (
        <section id="work">
            <div className="container">
                <h2><i className="fa-solid fa-briefcase"></i> {t('common.work')}</h2>

                {positions.map((item, index) => {
                    return <WorkExperienceItem key={`work-${index}`} data={item} />;
                })}

            </div>

        </section>
    );
}