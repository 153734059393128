import React from "react";
import { useTranslation } from 'react-i18next';
import CertificateItem from "./CertificateItem";

export default function Certificates() {

    const { t } = useTranslation();
    const certs = t("certificates.certList", { returnObjects: true });

    return (
        <section id="cert" className="bg-light bg-gradient">
            <div className="container">
                <h2><i className="fa-solid fa-award"></i> {t('common.certificates')}</h2>
                {certs.map((cert, index) => {
                    return (<CertificateItem data={cert} index={index} key={"cert-" + index} />)
                })}

                <div className="row mb-0">
                    <p>{t("certificates.codecademy")} <a href="https://www.codecademy.com/profiles/SteffenNHD" target="_blank" rel="noreferrer">{t("certificates.codecademyCTA")}</a></p>
                </div>
            </div>
        </section>
    );
}