import React from "react";


export default function AwardsItem({ data }) {
    return (
        <div className="row timeline-item">
            <div className="item-date col-12 col-lg-auto">
                <div className="date-box text-center bg-dark d-inline-block">
                    <h4 className="fs-5 p-2 m-0 text-white">{data.end}</h4>
                </div>
            </div>

            <div className="item-details col ">
                <h3 className="item-title fs-5 d-block pt-2 fw-bold">{data.name} | {data.organisation}</h3>
                <div className="item-desc">
                    <p className="fw-bold">{data.subtitle}</p>
                    <p>{data.desc}</p>
                </div>
            </div>
        </div>
    );
}