export const de = {
    "common": {
        "about_myself": "Über mich",
        "work": "Berufserfahrung",
        "education": "Ausbildung",
        "skills": "Kenntnisse",
        "certificates": "Zertifikate",
        "awards": "Auszeichnungen",
        "projects": "Projekte",
        "key_success": "Schlüsselerfolg",
        "today": "heute",
        "imprint": "Impressum",
        "privacy": "Datenschutzerklärung"
    },
    "about_myself": {
        "greeting": "Herzlich willkommen, ich bin",
        "job": "Entwickler und IT-Projektmanager",
        "about_myself_desc_1": "Ich interessiere mich leidenschaftlich für den Technologiesektor und das Programmieren. Es macht mir Spaß, neue Fähigkeiten zu erlernen, neue Dinge zu erschaffen oder Bestehendes zu verbessern. Aufgrund meiner Bachelor-Abschlüsse in Medieninformatik und Betriebswirtschaftslehre verfüge ich über ein solides Verständnis von Technik und Unternehmensprozessen.",
        "about_myself_desc_2": "Auf dieser Website erfahren Sie mehr über meine Berufserfahrung, Ausbildung und Fähigkeiten. Ich freue mich darauf, meine Leidenschaft für Technologie mit Ihnen zu teilen und bin offen für neue Herausforderungen und Möglichkeiten.",
        "about_myself_desc_3": "Vielen Dank für Ihren Besuch!",
        "social_profiles": "Meine Profile in den sozialen Medien"
    },
    "work_experience": [
        {
            "name": "Ausbildung zum Junior Java Full Stack Developer",
            "company": "YouGrow",
            "start": "09.2024",
            "end": "12.2024",
            "desc": [
                "Java, Objektorientierte Programmierung",
                "Git, Maven, Intellij",
                "Unit-Testing, Test-Driven Development, Code Quality",
                "Spring Boot, JPA, Spring Web, Spring Data",
                "JavaScript, HTML & CSS",
                "Angular Programmierung"
            ],
        },
        {
            "name": "Weiterbildung durch Online-Kurse",
            "company": "Privat",
            "start": "01.2024",
            "end": "09.2024",
            "desc": [
                "Schwerpunkt auf fachlicher Weiterbildung",
                "Front-End- und Full-Stack-Kurse auf Codecademy",
                "Weitere Kurse zu den Themen TypeScript und KI",
                "Meta Front-End Developer Kurs auf Coursera",
            ],
        },
        {
            "name": "Werkstudent Career Site Development",
            "company": "SAP SE, Walldorf",
            "start": "2021",
            "end": "2023",
            "desc": [
                "Wartung und Pflege der globalen SAP-Karriereseite (jobs.sap.com)",
                "Erstellung und Überarbeitung von Landingpages",
                "Unterstützung bei der Ausarbeitung eines neuen Designkonzepts der Karriereseite",
                "Programmierung und Implementierung des Designkonzepts",
                "Entwicklung und Anwendung von Prozessen zum automatischen Testen der Webseite"
            ],
            "key_success": "Entwicklung und Dokumentation von wiederverwendbaren Layout-Elementen (Web Components mit Stencil.js), die auch von SAP-Kunden eingesetzt werden."
        },
        {
            "name": "Global IT-Manager | Voltimum GmbH",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2019",
            "end": "2021",
            "desc": [
                "Durchführung zentraler Projekte im Bereich Web Development",
                "Definition und Abstimmung der technischen Anforderungen für Entwicklungsaufgaben",
                "Erstellung und Pflege technischer Dokumentationen",
                "Sicherstellung der Funktionalität und Usability entwickelter Applikationen",
                "Datenverwaltung und -pflege",
                "Entwicklung dynamischer Schnittstellen"
            ],
            "key_success": 'Erfolgreiche Markteinführung des Treueprogramms "Voltimum+".'
        },
        {
            "name": "IT-Projektmanager",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2017",
            "end": "2018",
            "desc": [
                "Leitung von Kunden-IT-Projekten im Bereich Web Development",
                "Requirements Engineering und Prototyping",
                "Koordination der Tasks mit den Entwicklern",
                "Bearbeitung von Kunden-Tickets",
                "Beratung von Kunden bei der Optimierung ihrer Websites"
            ],
            "key_success": "Erfolgreiche Migration mehrerer großer Kunden-Websites auf neue Drupal-Major-Versionen."
        },
        {
            "name": "Content- und Marketingmanager",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2014",
            "end": "2016",
            "desc": [
                "Content-Management und Portal-Management für DACH",
                "Programmierung von Kampagnen-Webseiten",
                "Programmierung des Newsletters",
                "Unterstützung bei der Planung und Umsetzung von IT-Services-Projekten"
            ],
            "key_success": "Entwicklung und Einführung des Voltimum Newsletter Tools."
        },
        {
            "name": "Praktikum Marketingassistent",
            "company": "Voltimum GmbH, Heidelberg",
            "start": "2012",
            "end": "2013",
        },
        {
            "name": "Praktikum Verwaltungsassistent",
            "company": "Bilfinger Berger, Mannheim",
            "end": "2009"
        },
        {
            "name": "Praktikum Zweiradmechaniker",
            "company": "Bike und Sport, Eberbach",
            "end": "2005"
        }
    ],
    "education": [
        {
            "name": "Medieninformatik (B.Sc.)",
            "organisation": "IU Internationale Hochschule",
            "end": "2023",
            "desc": [
                'Wahlpflichtmodule: "E-Commerce" und "Softwareentwicklung mit Python"',
                "Berufsbegleitendes Fernstudium",
            ]
        },
        {
            "name": "Betriebswirtschaftslehre (B.A.)",
            "organisation": "SRH Hochschule Heidelberg",
            "end": "2013",
            "desc": [
                'Wahlpflichtmodule: "Marketing" und "Sportmanagement"',
                "Präsenzstudium"
            ]
        },
        {
            "name": "Abitur ",
            "organisation": "Stephen-Hawking-Schule",
            "end": "2009"
        }
    ],
    "skills": {
        "skill_block_desc_1": "Hier finden Sie einen umfassenden Überblick über die Fähigkeiten und Kompetenzen, die ich im Laufe meiner Karriere entwickelt habe. Von technischen Fertigkeiten bis hin zu Soft Skills habe ich eine Vielzahl von Fähigkeiten entwickelt, die es mir ermöglichen, Herausforderungen effektiv zu meistern und einen wichtigen Beitrag in Projekten und Teams zu leisten.",
        "skill_block_desc_2": "Bitte klicken Sie auf die nachfolgenden Kategorien, um meine Kompetenzen im Detail zu erkunden.",
        "skillList": [
            {
                "category_name": "Front-End",
                "category_skills": [
                    {
                        "name": "HTML",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "CSS",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "JavaScript ",
                        "rating": 75,
                        "text": ""
                    },
                    {
                        "name": "Stencil.js",
                        "rating": 70,
                        "text": ""
                    },
                    {
                        "name": "Bootstrap Framework",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "Angular",
                        "rating": 50,
                        "text": ""
                    },
                    {
                        "name": "React",
                        "rating": 50,
                        "text": ""
                    },
                ]
            },
            {
                "category_name": "Back-End",
                "category_skills": [
                    {
                        "name": "SQL",
                        "rating": 70,
                        "text": ""
                    },
                    {
                        "name": "MySQL",
                        "rating": 65,
                        "text": ""
                    },
                    {
                        "name": "PHP",
                        "rating": 15,
                        "text": ""
                    },
                    {
                        "name": "Java",
                        "rating": 60,
                        "text": ""
                    },
                    {
                        "name": "Spring Web",
                        "rating": 50,
                        "text": ""
                    },
                    {
                        "name": "Node.js",
                        "rating": 45,
                        "text": ""
                    },
                    {
                        "name": "Express.js",
                        "rating": 40,
                        "text": ""
                    },
                ]
            },
            {
                "category_name": "Testing",
                "category_skills": [
                    {
                        "name": "Cypress",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Katalon",
                        "rating": 75,
                        "text": ""
                    }
                ]
            },
            {
                "category_name": "CMS",
                "category_skills": [
                    {
                        "name": "Drupal",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "WordPress",
                        "rating": 60,
                        "text": ""
                    }
                ]
            },
            {
                "category_name": "Software/Tools",
                "category_skills": [
                    {
                        "name": "Axure RP",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "Git",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Jira",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Microsoft Office",
                        "rating": 90,
                        "text": ""
                    },
                    {
                        "name": "Visual Studio Code",
                        "rating": 85,
                        "text": ""
                    },
                    {
                        "name": "Wrike",
                        "rating": 70,
                        "text": ""
                    },
                    {
                        "name": "Figma",
                        "rating": 40,
                        "text": ""
                    },
                    {
                        "name": "Docker",
                        "rating": 20,
                        "text": ""
                    },
                ]
            },
            {
                "category_name": "Operating Systems",
                "category_skills": [
                    {
                        "name": "Ubuntu",
                        "rating": 80,
                        "text": ""
                    },
                    {
                        "name": "Windows",
                        "rating": 95,
                        "text": ""
                    }
                ]
            },
            {
                "category_name": "Languages",
                "category_skills": [
                    {
                        "name": "Deutsch",
                        "rating": 100,
                        "text": ""
                    },
                    {
                        "name": "Englisch",
                        "rating": 80,
                        "text": ""
                    }
                ]
            }
        ]
    },
    "awards": [
        {
            "name": 'Gewinner "Voltimum Millennial Challenge"',
            "organisation": "Voltimum GmbH",
            "end": "2016",
            "subtitle": "Auszeichnung für besondere Initiative und Einsatz für das Unternehmen",
            "desc": "Außerhalb der regulären Arbeitszeit habe ich ein Drag & Drop Newsletter Tool entwickelt. Dieses wurde über mehr als 5 Jahre in 13 Ländern verwendet und reduzierte die Zeit für die Newsletter Erstellung erheblich."
        }
    ],
    "certificates": {
        "cta": "Zertifikat anzeigen",
        "codecademy": "Alle meine Codecademy Zertifikate finden Sie in meinem Profil.",
        "codecademyCTA": "Profil anzeigen",
        "certList": [
            {
                "year": "2024",
                "name": "Junior Java Full Stack Developer",
                "organisation": "YouGrow Academy",
                "skills": "HTML, CSS, JavaScript, Java, Spring, Angular, Version Control",
                "link": "/files/Zertifikat_YouGrow.pdf"
            },
            {
                "year": "2024",
                "name": "Meta Front-End Developer",
                "organisation": "Meta on Coursera",
                "skills": "HTML, CSS, JavaScript, React, Front-End Development, UX/UI Design, Version Control",
                "link": "https://www.coursera.org/account/accomplishments/professional-cert/H5G2J63LW5SU"
            },
            {
                "year": "2024",
                "name": "Create REST APIs with Spring and Java Skill Path",
                "organisation": "Codecademy",
                "skills": "Java, Spring, REST APIs, CRUD, JPA",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/60f1edf0ac9368001c6025c4"
            },
            {
                "year": "2024",
                "name": "Learn Java Course",
                "organisation": "Codecademy",
                "skills": "Java, Object-Oriented Programming, Conditionals and Control Flow, etc.",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/d3f89367b558583e361640f778191345"
            },
            {
                "year": "2024",
                "name": "Learn TypeScript Course",
                "organisation": "Codecademy",
                "skills": "Types, Functions, Complex Types, Type Narrowing, Advanced Object Types",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/56fb1e71303e37b643bb1905f31c8a09"
            },
            {
                "year": "2024",
                "name": "Full-Stack Engineer Career Path",
                "organisation": "Codecademy",
                "skills": "HTML, CSS, JavaScript, React, Redux, Git, Datenbanken, Node.js, Express.js, SQL, Front-End Development, Back-End Development, Full-Stack Development",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/ffd0f42cce1a44e9a0108b365047a0a6"
            },
            {
                "year": "2024",
                "name": "Front-End Engineer Career Path",
                "organisation": "Codecademy",
                "skills": "Web Foundations, HTML, CSS, JavaScript, Front-End Development",
                "link": "https://www.codecademy.com/profiles/SteffenNHD/certificates/2682884a0719474f96407efe432fdd87"
            }
        ]
    },
    "projects": {
        "desc": "Dieser Abschnitt zeigt meine neuesten Coding-Projekte und bietet Ihnen einen Einblick in meine Entwicklungskompetenzen. Im Rahmen meiner beruflichen Tätigkeit habe ich jedoch weitaus mehr programmiert. Dazu gehören unter anderem zahlreiche Kampagnenseiten und Newsletter sowie das Voltimum Newsletter-Tool und ein Werkzeug zur Übersetzung der Karriereseite von SAP. Aus verschiedenen Gründen kann ich diese Projekte hier leider nicht präsentieren. Auch die zahlreichen Projekte, für die ich als IT-Projektmanager verantwortlich war, sind in diesem Abschnitt nicht aufgeführt.",
        "cta": "Show Project",
        "projectList": [
            {
                "name": "Quizmaster (Full-Stack)",
                "type": "Referenzprojekt",
                "img": "/img/quizmaster.jpg",
                "stack": "Angular, Spring, PostgreSQL, Bootstrap",
                "shortText": "Die App Quizmaster wurde im Rahmen des Abschlussprojekts des YouGrow Bootcamps entwickelt. Die Bearbeitungszeit betrug etwa 1 ½ Wochen, in denen wir als Team aus 4 Personen zusammenarbeiteten.",
                "link": "https://qm.steffen-napravnik.de"
            },
            {
                "name": "Little Lemon Restaurant (Full-Stack)",
                "type": "Referenzprojekt",
                "img": "/img/little-lemon-angular.jpg",
                "stack": "Angular, Spring, PostgreSQL, Bootstrap, Mirage JS, Docker",
                "shortText": "Im Rahmen des YouGrow-Bootcamps wollte ich meine neu erworbenen Fähigkeiten direkt anwenden. Daher habe ich das Little-Lemon-Projekt auf Angular migriert und zusätzliche Funktionen hinzugefügt. Außerdem habe ich eine API mit Spring entwickelt.",
                "link": "https://llr.steffen-napravnik.de",
                "gitHub": "https://github.com/steffen-napravnik/Little-Lemon-Angular-Spring"
            },
            {
                "name": "Little Lemon Restaurant (Front-End)",
                "type": "Referenzprojekt",
                "stack": "React, Bootstrap",
                "img": "/img/little-lemon-react.jpg",
                "shortText": "Dieses Projekt war Teil des Meta-Front-End-Developer-Kurses. Die Aufgabe bestand darin, die Website eines fiktiven Restaurants als Single-Page-Application zu erstellen. Dazu gehörten die Gestaltung der Startseite und die Implementierung einer Tischreservierungsfunktion.",
            },
            {
                "name": "My portfolio website",
                "type": "Reales Projekt",
                "stack": "React, Bootstrap",
                "img": "/img/portfolio.jpg",
                "shortText": "Dieses Projekt entspricht der Seite, die Sie gerade besuchen. Im Rahmen des Front-End Engineer Kurses von Codecademy sollte eine Portfolio-Website erstellt werden. Zunächst habe ich diese als statische HTML-Seite programmiert und später in eine React Single-Page-Application umgewandelt.",
            },
            {
                "name": "SAP career site",
                "type": "Reales Projekt",
                "stack": "JavaScript, Bootstrap, StencilJS",
                "img": "/img/sap-careers.jpg",
                "shortText": "Als Werkstudent bei SAP war ich für die Wartung und Verbesserung der globalen Karriereseite von SAP verantwortlich. Ich entwickelte wiederverwendbare Webkomponenten mit StencilJS und Bootstrap, die die Wartbarkeit und Benutzerfreundlichkeit verbesserten.",
                "link": "https://jobs.sap.com/"
            }
        ]
    },
    "privacy": {
        "data_collection": "Datenerhebung",
        "data_collection_text": "Auf dieser Website werden keine persönlich identifizierbaren Informationen gesammelt, gespeichert oder verarbeitet. Diese Website verwendet keine Cookies.",
    }
}

export default de;