import React from "react";
import { useTranslation } from 'react-i18next';
import Project from "./ProjectItem"

export default function Projects() {

    const { t } = useTranslation();
    const projects = t("projects.projectList", { returnObjects: true });

    return (
        <section id="projects">
            <div className="container">
                <h2 className="text-white "><i className="fa-solid fa-code"></i> {t('common.projects')}</h2>
                <p className="text-white mb-5">{t("projects.desc")}</p>

                <div className="row row-cols-1 row-cols-lg-2 g-4">
                    {projects.map((project, index) => {
                        return (<Project data={project} index={index} key={"project-" + index} />)
                    })}
                </div>

            </div>
        </section>
    );
}