import React from "react";
import { useTranslation } from 'react-i18next';

export default function Hero() {
    const { t } = useTranslation();

    return (
        <div id="hero-container" className="container-fluid">

            <div id="hero-inner" className="d-flex align-items-center p-3 p-sm-0">
                <div id="hero-box" className="container shadow-lg">
                    <div className="row p-0 py-3 p-sm-4 p-md-5">

                        <div className="col-12 col-lg-4 order-lg-2">
                            <img id="hero-portrait" className="img-fluid mb-4" src="/img/portrait.jpeg" alt="Portrait of Steffen Napravnik" />
                        </div>

                        <div className="col-12 col-lg-8 order-lg-1">
                            <div className="fs-5">{t('about_myself.greeting')}</div>
                            <h1 className="fw-bold mb-0" style={{ color: "#082a4d" }}>Steffen Napravnik</h1>
                            <div className="fs-3 fw-bold mb-4">{t('about_myself.job')}</div>

                            <h2 className="fs-5 fw-bold">{t('common.about_myself')}</h2>
                            <p>{t('about_myself.about_myself_desc_1')}</p>
                            <p>{t('about_myself.about_myself_desc_2')}</p>
                            <p>{t('about_myself.about_myself_desc_3')}</p>

                            <div id="social" className="mt-4">
                                <h2 className="fs-5 fw-bold mb-0">{t('about_myself.social_profiles')}</h2>
                                <ul className="nav list-unstyled d-flex fs-2">
                                    <li><a className="text-muted" href="https://www.linkedin.com/in/steffen-napravnik" target="_blank" rel='noopener noreferrer'><i className="fa-brands fa-linkedin"></i></a></li>
                                    <li className="ms-2"><a className="text-muted" href="https://www.xing.com/profile/Steffen_Napravnik" target="_blank" rel='noopener noreferrer'><i className="fa-brands fa-square-xing"></i></a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}