import React from "react";
import {useTranslation} from 'react-i18next';

export default function Home() {
    const {t} = useTranslation();
    return (
        <div id="hero-container" className="container-fluid">

            <div id="hero-inner" className="d-flex align-items-center p-3 p-sm-0">
                <div id="hero-box" className="container shadow-lg">
                    <div className="row p-0 py-3 p-sm-4 p-md-5">
                        <div className="col">
                            <section id="imprint" className="p-0">
                                <h2><i className="fa-solid fa-section"></i> {t('common.imprint')}</h2>
                                <p className="fs-4 fw-bold mt-2">Steffen Napravnik</p>
                                <p>Wittelsbacherallee 67 <br/>
                                    69181 Leimen <br/>
                                    Germany</p>

                                <p>Email: info@steffen-napravnik.de</p>
                                <ul className="nav list-unstyled d-flex fs-2">
                                    <li><a className="text-muted"
                                           href="https://www.linkedin.com/in/steffen-napravnik-86927777/"
                                           target="_blank" rel='noopener noreferrer'><i
                                        className="fa-brands fa-linkedin"></i></a></li>
                                    <li className="ms-2"><a className="text-muted"
                                                            href="https://www.xing.com/profile/Steffen_Napravnik"
                                                            target="_blank" rel='noopener noreferrer'><i
                                        className="fa-brands fa-square-xing"></i></a></li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <section style={{minHeight: "300px"}}></section>

        </div>
    )
}