import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {en} from './locales/en';
import {de} from './locales/de';

// the translations
const resources = {
    en: {
        translation: en
    },
    de: {
        translation: de
    }
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "de",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;