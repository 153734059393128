import React from "react";
import { useTranslation } from 'react-i18next';

export default function ProjectItem({ data, index }) {
    const { t } = useTranslation();
    const link = data.link ? <a className="btn btn-outline-light btn-sm me-2" href={data.link} target="_blnak" rel="noreferrer">{t("projects.cta")}</a> : null;
    const gitHub = data.gitHub ? <a className="btn btn-outline-light btn-sm" href={data.gitHub} target="_blnak" rel="noreferrer">GitHub</a> : null;
    const stack = data.stack ? <p className="fst-italic">Stack: {data.stack}</p> : null;

    return (
        <div className="col" key={`project-${index}`}>
            <div className="glass-box card h-100 shadow-lg text-white rounded-0">
                <div className="ratio ratio-16x9 bg-img" style={{ backgroundImage: "url(" + data.img + ")" }}></div>
                <div className="card-body">
                    <h6 className="card-subtitle mb-2 text-uppercase">{data.type}</h6>
                    <h3 className="card-title fs-5 fw-bold">{data.name}</h3>
                    {stack}
                    <p className="card-text">{data.shortText}</p>
                    <div>
                        {link}
                        {gitHub}
                    </div>
                </div>
            </div>
        </div>
    );
}