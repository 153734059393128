import React from "react";
import { useTranslation } from 'react-i18next';
import AwardsItem from "./AwardsItem";


export default function Awards() {
    const { t } = useTranslation();
    const awards = t("awards", { returnObjects: true });
    return (
        <section id="awards">

            <div className="container">
                <h2><i className="fa-solid fa-trophy"></i> {t('common.awards')}</h2>

                {awards.map((item, index) => {
                    return <AwardsItem key={`awards-${index}`} data={item} />
                })}

            </div>

        </section>
    );
}