import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Skill from "./SkillItem"
import Popover from 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/tab';

export default function Skills() {

    const { t } = useTranslation();
    const skills = t("skills.skillList", { returnObjects: true });

    useEffect(() => {
        // Skills popover initialize
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))
    })

    return (
        <section id="skills">
            <div className="container">

                <h2><i className="fa-solid fa-star"></i> {t('common.skills')}</h2>
                <p>{t('skills.skill_block_desc_1')}</p>

                <p>{t('skills.skill_block_desc_2')}</p>

                <ul className="nav nav-pills mt-4" role="tablist">

                    {skills.map((item, index) => {
                        return (
                            <li className="nav-item" role="presentation" key={`skillCatLink-${index}`}>
                                <a
                                    className={index === 0 ? 'nav-link active' : 'nav-link'}
                                    id={`tab-${index}`}
                                    data-bs-toggle="tab"
                                    href={`#tabpanel-${index}`}
                                    role="tab"
                                    aria-controls={`tabpanel-${index}`}
                                    aria-selected={index === 0 ? 'true' : 'false'}>
                                    {item.category_name}
                                </a>
                            </li>

                        )
                    })}

                </ul>


                <div className="tab-content pt-4" id="tab-content">

                    {skills.map((item, index) => {
                        return (
                            <div className={`tab-pane ${index === 0 ? 'active' : ''}`} id={`tabpanel-${index}`} role="tabpanel" aria-labelledby={`tab-${index}`} key={`skillCatTab-${index}`}>
                                <div className="row">

                                    {item.category_skills.map(skill => {
                                        return (
                                            <Skill skill={skill} key={skill.name.replace(/\s/g, '')} />
                                        )
                                    })}

                                </div>
                            </div>
                        )
                    })}


                </div>
            </div>
        </section>
    );
}