import React from "react";
import { useTranslation } from 'react-i18next';
import EducationItem from "./EducationItem";

export default function Education({ data }) {
    const { t } = useTranslation();
    const education = t("education", { returnObjects: true });
    return (
        <section id="edu" className="bg-light bg-gradient">

            <div className="container">
                <h2><i className="fa-solid fa-graduation-cap"></i> {t('common.education')}</h2>

                {education.map((item, index) => {
                    return <EducationItem key={`edu-${index}`} data={item} />
                })}

            </div>

        </section>
    );
}